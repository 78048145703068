<template>
  <div>
    <v-row class="pa-0 ma-0 mx-2">
      <v-col cols="12">
        <v-card class="border-radius-lg mt-5">
          <v-row class="justify-space-between pt-3 pb-5 px-7">
            <div>
              <h5 class="text-h5 my-2 font-weight-bold text-typo">Kunden</h5>
            </div>
            <v-btn
              @click="openAddCustomerModal()"
              :elevation="0"
              color="#cb0c9f"
              class="font-weight-bolder btn-dark bg-light py-4 px-4 mx-2"
            >
              Kunde hinzufügen
            </v-btn>
          </v-row>
          <v-card
            @click="selectCustomer(customer)"
            class="border-radius-md"
            v-for="customer in customers.data"
            :key="customer.id"
          >
            <div>
              <v-divider></v-divider>
              <v-row class="align-center pa-0 ma-0 py-2">
                <v-avatar
                  size="50"
                  class="ma-3 text-uppercase text-dark"
                  color="#F3F5F6"
                >
                  <div v-if="customer.customer">
                    {{ customer.customer.name[0] }}
                  </div>
                  <div v-else>
                    {{ customer.placeholder[0] }}
                  </div>
                </v-avatar>
                <v-col>
                  <div
                    v-if="customer.customer"
                    class="my-1 font-weight-bolder text-md"
                  >
                    {{ customer.customer.name }}
                  </div>
                  <div v-else class="my-1 font-weight-bolder text-md">
                    {{ customer.placeholder }}
                  </div>
                  <div
                    v-if="customer.accepted"
                    class="my-1 font-weight-normal text-md"
                  >
                    Kunde seit:
                    {{ customer.updatedAt | moment("DD.MM.YYYY") }}
                  </div>
                </v-col>
                <v-chip
                  v-if="customer.accepted == false"
                  class="bg-danger text-white mr-2"
                >
                  Einladung abgelehnt
                </v-chip>
                <v-chip v-if="customer.accepted == null" class="bg-light mr-2">
                  Einladung ausstehend
                </v-chip>
                <v-chip
                  v-if="customer.accepted == true"
                  class="bg-success text-white mr-2"
                >
                  Einladung bestätigt
                </v-chip>
              </v-row>
            </div>
          </v-card>
        </v-card></v-col
      ></v-row
    >
    <!-- <v-dialog v-model="customerInfoModal" max-width="500px" persistent>
      <CustomerInfo :customer="selectedCustomer" @close="closeCustomerModal()" />
    </v-dialog> -->
  </div>
</template>

<script>
export default {
  name: "Customers",
  data() {
    return {};
  },
  methods: {},
};
</script>
